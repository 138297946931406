<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					v-if="hasAccess('NewUser')"
					variant="success"
					class="float-right"
					@click="$router.push({ name: 'NewUser'});">
					{{ translate('create') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-md-4 col-sm-6 col-xs-12">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,pre_distributor,agency'}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12">
						<div class="form-group">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>
							<select
								id="countryName"
								v-model="filters.country"
								:disabled="staffCountry"
								name="country"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="stage"
								class="label">{{ translate('stage') }}</label>
							<select
								id="stage"
								v-model="filters.stage"
								name="stage"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="stage in stages"
									:key="stage"
									:value="stage">
									{{ translate(stage) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="package"
								class="label">{{ translate('package') }}</label>
							<select
								id="package"
								v-model="filters.package"
								name="package"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option value="no_package">
									{{ translate('no_package') }}
								</option>
								<option
									v-for="item in packagesData"
									:key="item.id"
									:value="item.attributes.code_name">
									{{ translate(item.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="rank"
								class="label">{{ translate('rank') }}</label>
							<select
								id="rank"
								v-model="filters.rank"
								name="rank"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in ranksData"
									:key="item.id"
									:value="item.attributes.code_name">
									{{ translate(item.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="permanent_rank"
								class="label">{{ translate('permanent_rank') }}</label>
							<select
								id="permanent_rank"
								v-model="filters.permanent_rank"
								name="permanent_rank"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in ranksData"
									:key="item.id"
									:value="item.attributes.code_name">
									{{ translate(item.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('id')">
							{{ translate('user_id') }}
							<sort field="id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('first_name')">
							{{ translate('name') }}
							<sort field="first_name" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('email')">
							{{ translate('email') }}
							<sort field="email" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('type')">
							{{ translate('type') }}
							<sort field="type" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('product')">
							{{ translate('package') }}
							<sort field="product" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('rank')">
							{{ translate('rank') }}
							<sort field="rank" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('permanent_rank')">
							{{ translate('permanent_rank') }}
							<sort field="permanent_rank" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('stage')">
							{{ translate('stage') }}
							<sort field="stage" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('country')">
							{{ translate('country') }}
							<sort field="country" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('status')">
							{{ translate('status') }}
							<sort field="status" />
						</th>
						<!--<th class="border-top-0 text-center">-->
						<!--{{ translate('options') }}-->
						<!--</th>-->
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index"
						class="text-nowrap">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<template v-if="hasPermission() && admin.includes($user.details().type)">
								<span
									class="pointer badge badge-primary"
									@click="toDashboard({ userId: item.id }, { userType: item.attributes.type })">{{ item.id }}</span>
							</template>
							<template v-else>
								<span class="pointer badge badge-primary">{{ item.id }}</span>
							</template>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.type) }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.product) }}
						</td>
						<td class="align-middle">
							<div class="d-flex align-items-center">
								{{ translate(item.attributes.rank) }}
								<template v-if="subRankIndicator(item.attributes, 'current_sub_rank') !== null">
									<img
										:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes, 'current_sub_rank')}_indicator.png`"
										class="ml-1 mb-1"
										alt="elite">
								</template>
							</div>
						</td>
						<td class="align-middle">
							<div class="d-flex align-items-center">
								{{ translate(item.attributes.permanent_rank) }}
								<template v-if="subRankIndicator(item.attributes, 'permanent_sub_rank') !== null">
									<img
										:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes, 'permanent_sub_rank')}_indicator.png`"
										class="ml-1 mb-1"
										alt="elite">
								</template>
							</div>
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.stage) }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="getCountry(item.attributes.country)"
								:src="item.attributes.country ? require(`@/assets/images/common/countries/select/${item.attributes.country.toUpperCase()}.png`) : ''"
								:alt="getCountry(item.attributes.country)">
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<!-- <td class="align-middle text-center">
							<template v-if=" hasAccess('UpdateUser', { userId: item.id }) || hasAccess('UpdatePassword', { userId: item.id })">
								<b-button
									v-b-tooltip.hover
									v-if="hasAccess('UpdateUser', { userId: item.id })"
									:title="translate('update_thing', { name: translate('user') })"
									variant="primary"
									class="mr-1 bg-primary-alt"
									@click="$router.push({ name: 'UpdateUser', params: { userId: item.id } });">
									<i
										class="fa fa-user-edit"
										aria-hidden="true" />
								</b-button>
								<b-button
									v-if="hasAccess('UpdatePassword', { userId: item.id })"
									v-b-tooltip.hover
									:title="translate('update_thing', { name: translate('password') })"
									variant="primary"
									class="bg-primary-alt"
									@click="$router.push({ name: 'UpdatePassword', params: { userId: item.id, username: item.attributes.username } });">
									<i
										class="fa fa-key"
										aria-hidden="true" />
								</b-button>
							</template>
						</td>-->
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>

import {
	Profile, Users, Tooltip, Ranks, Packages, Grids, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import SubRanks from '@/mixins/SubRanks';
import DataFilter from '@/components/DataFilter';
import User from '@/util/User';
import Country from '@/util/Country';
import GENERAL_INFO from '@/util/GeneralInformation';
import { SEARCH_USERS } from '@/config/endpoint';
import { adminDashboardPermissions } from '@/settings/Dashboard';
import { admin } from '@/settings/Roles';
import { PAGINATION } from '@/settings/RequestReply';
import { STATUSES as statuses, STAGES as stages } from '@/settings/Statuses';
import { S3_PATH } from '@/settings/Images';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Users',
	messages: [Profile, Users, Tooltip, Ranks, Packages, Grids, Countries],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect, SubRanks],
	data() {
		return {
			user: new User(),
			statuses,
			stages,
			alert: new this.$Alert(),
			endpoint,
			ranks: new GENERAL_INFO(),
			packages: new GENERAL_INFO(),
			admin,
			countryData: new Country(),
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
	computed: {
		loading() {
			return !!this.user.data.loading;
		},
		errors() {
			return this.user.data.errors;
		},
		pagination() {
			return this.user.data.pagination;
		},
		data() {
			try {
				const { data } = this.user.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		ranksData() {
			try {
				return this.ranks.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		packagesData() {
			try {
				return this.packages.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		countries() {
			try {
				return this.countryData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		staffCountry() {
			return this.$user.details().country !== undefined;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	watch: {
		'filters.status': function (value) { // eslint-disable-line func-names
			this.filters.status = typeof value === 'undefined' || value === '' ? undefined : value;
		},
		'filters.countryId': function (value) { // eslint-disable-line func-names
			this.filters.countryId = typeof value === 'undefined' || value === '' ? undefined : value;
		},
	},
	mounted() {
		this.ranks.getRanks();
		this.packages.getPackages();
		this.user.getUsers();
		this.countryData.getCountries();
		if (this.staffCountry) {
			this.filters.country = this.countryCode;
		}
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.user.getUsers(options);
		},
		hasPermission() {
			return adminDashboardPermissions.some((item) => this.$can(item.section, item.view));
		},
		getCountry(item) {
			try {
				return this.translate(item.toLowerCase());
			} catch (error) {
				return '';
			}
		},
	},
};
</script>
